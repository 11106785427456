import React from "react";
import { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { IoWalletOutline, IoMenu } from "react-icons/io5";
import { ReactComponent as Ynft } from "../assets/ynft.svg";
import NavbarItems from "./navbarItems";
import { toggleWallet } from "../utils";

export default function Navbar({ toggleMenuVis }) {
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  useEffect(() => {
    document.addEventListener("window.userAcc", () =>
      setIsWalletConnected(window.userAcc != null)
    );

    return document.removeEventListener("window.userAcc", null);
  }, []);

  const truncateAddress = (address) =>
    address.substring(0, 5) +
    "..." +
    address.substring(address.length - 5, address.length);

  return (
    <div className="w-full max-w-screen-2xl m-auto flex justify-between items-center">
      <a href="#">
        <Ynft className="h-10 md:h-16" />
      </a>

      <IconContext.Provider
        value={{
          className:
            "block lg:hidden mr-6 text-pink text-2xl focus:shadow-pink focus:cursor-pointer focus:bg-pink/10 transition-all svg-pink",
        }}
      >
        <div className="flex">
          <div onClick={toggleWallet}>
            <IoWalletOutline />
          </div>
          <div onClick={() => toggleMenuVis(true)}>
            <IoMenu />
          </div>
        </div>
      </IconContext.Provider>
      <div className="hidden lg:flex items-center ">
        <NavbarItems />
        <div
          onClick={toggleWallet}
          className="hidden lg:flex mx-6 px-5 py-2  transparent border border-pink rounded font-bold text-pink items-center hover:shadow-pink hover:cursor-pointer hover:bg-pink/10 transition-all"
        >
          {isWalletConnected
            ? truncateAddress(window.userAcc.networkAccount.addr)
            : "Connect Wallet"}
          <div className="h-5 w-5 m-1 ml-2 p-1 border border-pink rounded-full ">
            <div className="h-full w-full bg-pink rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
}
