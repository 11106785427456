import * as backend from "./index.main.mjs";
import { calcRewardPerToken, parseFloatingPoint } from "./utils";

export const fetchGlobalGameData = async () => {
  const initialState = await window.readCtc.views
    .initialState()
    .then((v) => v[1]);
  const globalState = await window.readCtc.views
    .globalState()
    .then((v) => v[1]);
  console.log(JSON.stringify(initialState));
  console.log(JSON.stringify(globalState));

  let currNetTime;
  const stakingEndTime = initialState.stakingEndTime.toNumber();
  const lastUpdateTime = globalState.lastUpdateTime.toNumber();
  const rewardPerToken = parseFloatingPoint(globalState.rewardPerToken);
  const totalStake = globalState.totalStake.toNumber();

  const isStakingPeriodPrm = window.reach
    .getNetworkTime()
    .then((v) => v <= stakingEndTime);
  const endTimePrm = window.reach.getNetworkTime().then((v) => {
    currNetTime = v;
    return new Date().getTime() + (stakingEndTime - v) * 3.8 * 1000;
  });
  const stakeTokenPrm = window.reach
    .createAccount()
    .then((v) => v.tokenMetadata(initialState.stakeToken))
    .then((v) => {
      return {
        ...v,
        id: initialState.stakeToken,
        decimals: v.decimals.toNumber(),
      };
    });
  const nftPrm = window.reach
    .createAccount()
    .then((v) => v.tokenMetadata(initialState.nft))
    .then((v) => {
      return { ...v, id: initialState.nft, decimals: v.decimals.toNumber() };
    });

  const [isStakingPeriod, endTime, stakeToken, nft] = await Promise.all([
    isStakingPeriodPrm,
    endTimePrm,
    stakeTokenPrm,
    nftPrm,
  ]);

  const latestRewardPerToken = calcRewardPerToken(
    totalStake,
    rewardPerToken,
    lastUpdateTime,
    Math.min(currNetTime, stakingEndTime)
  );

  return {
    isStakingPeriod: isStakingPeriod,
    totalStake: totalStake,
    endTime: endTime,
    stakeToken: stakeToken,
    nft: nft,
    isPrizeClaimed: globalState.prizeClaimed,
    ctcRewardPerToken: rewardPerToken,
    latestRewardPerToken: latestRewardPerToken,
    winner: globalState.winner[1]
      ? window.reach.formatAddress(globalState.winner[1])
      : null,
  };
};

// Search and find latest appID deployed from admin account
export const fetchLatestAppID = async () => {
  // Check if a transaction is a app setup transaction
  const checkIfAppSetupTx = (v) => {
    const appTx = v["application-transaction"];
    const accountsArrEmpty = appTx["accounts"].length == 0;
    const appArgsArrCheck = appTx["application-args"][0] == "A6+jPA==";
    const appIDnotNull = appTx["application-id"] != null;
    const foreignAppsArrEmpty = appTx["foreign-apps"].length == 0;
    const checkForeignAssetsArr = appTx["foreign-assets"].length == 2;

    const feeCheck = v["fee"] == 5000;

    const innerTxnsCheck = v["inner-txns"]?.length == 3;

    return (
      accountsArrEmpty &&
      appArgsArrCheck &&
      appIDnotNull &&
      foreignAppsArrEmpty &&
      checkForeignAssetsArr &&
      feeCheck &&
      innerTxnsCheck
    );
  };

  // Get AlgoSDK indexer client from Reach provider
  const indexerClient = await window.reach.getProvider().then((v) => v.indexer);

  // Next token value for paginated results
  let nextToken = null;
  do {
    // Get filtered transactions from latest to earliest
    const res = await indexerClient
      .searchForTransactions()
      .address(process.env.REACT_APP_ADMIN_ADD)
      .addressRole("sender")
      .txType("appl")
      .nextToken(nextToken)
      .do();

    // Iterate over all transactions
    for (const v of res["transactions"]) {
      if (checkIfAppSetupTx(v)) {
        return v["application-transaction"]["application-id"]; // If latest app set up transaction found, return appID immediately
      }
    }

    // Set nextToken for next iteration
    nextToken = res["next-token"];
  } while (nextToken != null); // Continue loop until no more paginated results
};

export const fetchWinnerHistory = async () => {
  const checkIfWinnerSetTx = (v) => {
    const appTx = v["application-transaction"];
    const accountsArrEmpty = appTx["accounts"].length == 0;
    const appArgsArrCheck = appTx["application-args"][0] == "J+/ebA==";
    const appIDnotNull = appTx["application-id"] != null;
    const foreignAppsArrEmpty = appTx["foreign-apps"].length == 0;
    const foreignAssetsArrEmpty = appTx["foreign-assets"].length == 0;

    const feeCheck = v["fee"] == 1000;

    const innerTxnsEmpty = (v["inner-txns"]?.length ?? 0) == 0;

    return (
      accountsArrEmpty &&
      appArgsArrCheck &&
      appIDnotNull &&
      foreignAppsArrEmpty &&
      foreignAssetsArrEmpty &&
      feeCheck &&
      innerTxnsEmpty
    );
  };

  // Get AlgoSDK indexer client from Reach provider
  const indexerClient = await window.reach.getProvider().then((v) => v.indexer);

  const winnerSelectionArr = [];
  let nextToken = null;
  do {
    const res = await indexerClient
      .searchForTransactions()
      .address(process.env.REACT_APP_ADMIN_ADD)
      .addressRole("sender")
      .txType("appl")
      .nextToken(nextToken)
      .do();

    await Promise.all(
      res["transactions"].map(async (v) => {
        if (checkIfWinnerSetTx(v)) {
          try {
            const appID = v["application-transaction"]["application-id"];
            const readCtc = await window.reach.contract(backend, appID);
            const initialState = await readCtc.views
              .initialState()
              .then((v) => v[1]);
            const globalState = await readCtc.views
              .globalState()
              .then((v) => v[1]);
            const nftID = window.reach.bigNumberToNumber(initialState.nft);
            const winner = window.reach.formatAddress(globalState.winner[1]);

            const winnerSelection = {
              txid: v["id"],
              datetime: new Date(v["round-time"] * 1000),
              winner: winner,
              prize: nftID,
              appID: v["application-transaction"]["application-id"],
            };
            winnerSelectionArr.push(winnerSelection);
          } catch (e) {}
        }
      })
    );
    nextToken = res["next-token"];
  } while (nextToken != null);

  return winnerSelectionArr;
};

export const fetchUserGameData = async () => {
  const localState = await window.readCtc.views
    .localState(window.userAcc.networkAccount.addr)
    .then((v) => v[1]);
  console.log(JSON.stringify(localState));

  return {
    userHasWithdrawn: localState.userHasWithdrawn,
    userBalance: localState.userHasWithdrawn
      ? 0
      : localState.userBalance.toNumber(),
    userRewards: parseFloatingPoint(localState.userRewards),
    userRewardPerTokenPaid: parseFloatingPoint(
      localState.userRewardPerTokenPaid
    ),
  };
};
