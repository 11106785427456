import React from "react";
import Tilt from "react-parallax-tilt";
import nftbg from "../assets/nft-bg.png";
import yieldling from "../assets/yieldling.png";
import { useState, useEffect } from "react";
import CountUp from "react-countup";
import { numOfDP } from "../utils";
import { ReactComponent as AlgoIcon } from "../assets/algo-logo.svg";

export default function NftDisplayModule({ ticketAmount, countDown }) {
  const [lastTicketAmt, setLastTicketAmt] = useState(null);
  const [currTicketAmt, setCurrTicketAmt] = useState(null);
  const [displayDecimals, setDisplayDecimals] = useState(0);

  useEffect(() => {
    if (ticketAmount != null) {
      const newLastTicketAmt =
        currTicketAmt != null ? currTicketAmt : ticketAmount;
      setLastTicketAmt(newLastTicketAmt);
      setCurrTicketAmt(ticketAmount);
      setDisplayDecimals(
        Math.max(numOfDP(newLastTicketAmt), numOfDP(ticketAmount))
      );
    }
  }, [ticketAmount]);

  const getReturnValues = (countDown) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div
      className="relative justify-between bg-cover max-w-lg h-[39rem] rounded-2xl mx-5 md:m-auto"
      style={{ backgroundImage: `url(${nftbg})` }}
    >
      <div className="absolute w-full" style={{ transform: "translateZ(0)" }}>
        <Tilt tiltReverse={true}>
          <div
            className=" center mx-auto my-16 bg-cover bg-center w-3/4 md:w-2/3 h-96 bg-pink rounded-lg"
            style={{ backgroundImage: `url(${yieldling})` }}
          ></div>
        </Tilt>
      </div>

      <div className="absolute bottom-0 backdrop-blur-md bg-[#2E404040] rounded-b-2xl">
        <div className="mx-5 mt-3">
          <div className="flex justify-between text-blue text-xl">
            <p>Yieldling Original #013</p>
            <p>@Artistname</p>
          </div>
          <p className="mt-4 text-white text-left">
            Hatching 1 at a time. 500 Flamingo Flock. All Yieldlings are 1 of 1s
            excluding the 'Originals' and 'Yields'. There are no vaulted
            Yieldlings.
          </p>
          <div className="flex justify-between mt-12 mb-7">
            <div className="text-left">
              <p className="text-white">Current Tickets</p>
              <div className="flex items-center my-1">
                <AlgoIcon className="fill-blue h-5 w-5 mr-3 hover:none" />
                <p className="text-blue text-xl">
                  {ticketAmount != null ? (
                    <CountUp
                      start={lastTicketAmt}
                      end={currTicketAmt}
                      // onEnd={() => setDisplayDecimals(numOfDP(currTicketAmt))}
                      // decimals={displayDecimals}
                      decimals={2}
                    />
                  ) : (
                    "-"
                  )}{" "}
                  Tickets
                </p>
              </div>
            </div>
            <div className="text-left">
              <p className="text-white">Ending In</p>
              <div>
                <p className="text-blue text-xl">
                  {countDown != null ? getReturnValues(countDown) : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
