import React from "react";

export default function navbarItems() {
  return (
    <div>
      <a href="#" className="nav-link">
        Discover
      </a>
      <a href="#" className="nav-link">
        Buy
      </a>
      <a href="#" className="nav-link">
        Sell
      </a>
      <a href="#" className="nav-link">
        Mint
      </a>
      <a href="#" className="nav-link">
        NFT Prize Games
      </a>
    </div>
  );
}
