import * as backend from "./index.main.mjs";

export const toggleWallet = async () => {
  if (window.userAcc) {
    window.location.reload();
  } else if (window.reach != null && window.currAppID != null) {
    window.userAcc = await window.reach.getDefaultAccount();
    window.userCtc = window.userAcc.contract(backend, window.currAppID);
    document.dispatchEvent(new Event("window.userAcc"));
  }
};

export const connectWallet = async () => {
  if (!window.userAcc) {
    await toggleWallet();
  }
};

export const toSU = (amt, decimals) =>
  window.reach.formatWithDecimals(amt, decimals);
export const toAU = (amt, decimals) => amt * 10 ** decimals;

export const numOfDP = (numStr) =>
  numStr.includes(".") ? numStr.split(".")[1].length : 0;

export const calcRewardPerToken = (
  totalStake,
  rewardPerToken,
  lastUpdateTime,
  calcTime
) =>
  totalStake != 0
    ? rewardPerToken + (1000000 / totalStake) * (calcTime - lastUpdateTime)
    : rewardPerToken;

export const calcUserRewards = (
  userRewards,
  userBalance,
  rewardPerToken,
  userRewardPerTokenPaid
) => userRewards + userBalance * (rewardPerToken - userRewardPerTokenPaid);

export const parseFloatingPoint = (v) => {
  const absNum = divBigInt(v.i.i.toBigInt(), v.i.scale.toBigInt());
  return v.sign ? absNum : -absNum;
};

const divBigInt = (a, b, precision = 100) =>
  Number((a * BigInt(precision)) / b) / precision;
