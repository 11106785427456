import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { fetchWinnerHistory } from "../api";

export default function WinnerHistory() {
  const [winnerHistory, setWinnerHistory] = useState([]);

  useEffect(() => {
    document.addEventListener("window.reach", () => {
      if (window.reach) {
        fetchWinnerHistory()
          .then((v) =>
            [...v].sort((a, b) => (a.datetime < b.datetime ? 1 : -1))
          )
          .then((v) => setWinnerHistory(v));
      }
    });

    return document.removeEventListener("window.reach", null);
  }, []);

  return (
    <>
      {/* Longer format for standard screens */}
      <table className="w-full hidden md:table text-white mt-12 mb-4 mx-auto text-left">
        <thead>
          <tr>
            <th className="px-5 md:text-sm lg:text-lg font-normal text-violet-light pr-10">
              Transaction ID
            </th>
            <th className="px-5 md:text-sm lg:text-lg font-normal text-violet-light pr-10">
              Date/Time
            </th>
            <th className="px-5 md:text-sm lg:text-lg font-normal text-violet-light pr-10">
              Winner
            </th>
            <th className="px-5 md:text-sm lg:text-lg font-normal text-violet-light pr-10">
              Prize ID
            </th>
          </tr>
        </thead>
        <tbody>
          {winnerHistory.map((winnerHistoryObj) => (
            <>
              <div className="md:h-1 lg:h-5" />
              <tr>
                {/* first 4 and last 4 characters of TxID */}

                <td>
                  <div className="bg-gradient-to-b from-[#16162F] to-[#0e0e1a]">
                    <div className="p-5 md:text-sm lg:text-lg ">
                      {winnerHistoryObj.txid.substring(0, 4)}...
                      {winnerHistoryObj.txid.substring(
                        winnerHistoryObj.txid.length - 4
                      )}
                    </div>
                  </div>
                </td>

                <td>
                  <div className="bg-gradient-to-b from-[#16162F] to-[#0e0e1a]">
                    <div className="p-5 md:text-sm lg:text-lg ">
                      {<Moment date={winnerHistoryObj.datetime} />}
                    </div>
                  </div>
                </td>
                {/* first 4 and last 4 characters of account address */}
                <td>
                  <div className="bg-gradient-to-b from-[#16162F] to-[#0e0e1a]">
                    <div className="p-5 md:text-sm lg:text-lg ">
                      {winnerHistoryObj.winner.substring(0, 4)}...
                      {winnerHistoryObj.winner.substring(
                        winnerHistoryObj.winner.length - 4
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="bg-gradient-to-b from-[#16162F] to-[#0e0e1a]">
                    <div className="p-5 md:text-sm lg:text-lg ">
                      {winnerHistoryObj.prize}
                    </div>
                  </div>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>

      {/* Shorter format for smaller screens */}
      <table className="w-full md:hidden text-white mt-8 mb-4 text-left text-sm">
        <thead>
          <tr>
            <th className="font-md text-violet-light">Tx ID</th>
            <th className="font-md text-violet-light">Date</th>
            <th className="font-md text-violet-light">Winner</th>
            <th className="font-md text-violet-light">Prizes</th>
          </tr>
        </thead>
        <tbody>
          <div className="h-3" />
          {winnerHistory.map((winnerHistoryObj) => (
            <>
              <tr>
                {/* first 4 and last 4 characters of TxID */}
                <td>
                  <div className="bg-gradient-to-b from-[#16162F] to-[#0e0e1a]">
                    <div className="p-2">
                      {winnerHistoryObj.txid.substring(0, 4)}...
                    </div>
                  </div>
                </td>
                <td>
                  <div className="bg-gradient-to-b from-[#16162F] to-[#0e0e1a]">
                    <div className="p-2">
                      {
                        <Moment
                          date={winnerHistoryObj.datetime}
                          format="DD MMM 'YY"
                        />
                      }
                    </div>
                  </div>
                </td>
                {/* first 4 and last 4 characters of account address */}
                <td>
                  <div className="bg-gradient-to-b from-[#16162F] to-[#0e0e1a] ">
                    <div className="p-2">
                      {winnerHistoryObj.winner.substring(0, 4)}...
                    </div>
                  </div>
                </td>
                <td>
                  <div className="bg-gradient-to-b from-[#16162F] to-[#0e0e1a]">
                    <div className="p-2">
                      {winnerHistoryObj.prize.toString().substring(0, 4)}...
                    </div>
                  </div>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </>
  );
}
