import React, { useState } from "react";
import ReactDom from "react-dom";
import { IoClose } from "react-icons/io5";
import { IconContext } from "react-icons";
import { connectWallet } from "../utils";
import { ReactComponent as Yicon } from "../assets/yicon.svg";
import { ReactComponent as YiconGold } from "../assets/yicon-gold.svg";
import Sparkle from "react-sparkle";
import { GridLoader } from "react-spinners";
import yieldling from "../assets/yieldling.png";

export default function CheckWinnerModule({
  open,
  onClose,
  nft,
  winner,
  isPrizeClaimed,
}) {
  const [userWon, setUserWon] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);

  const onCheckNowClick = async () => {
    setIsChecking(true);
    await connectWallet();
    await new Promise((r) => setTimeout(r, 3000));
    setUserWon(winner == window.userAcc.networkAccount.addr);
    setIsChecking(false);
  };

  const onClaimNowClick = async () => {
    if (userWon) {
      setIsClaiming(true);
      await connectWallet();
      if (!isPrizeClaimed) {
        if (!(await window.userAcc.tokenAccepted(nft.id)))
          await window.userAcc.tokenAccept(nft.id);
        await window.userCtc.apis.claim();
      }
      setIsClaiming(false);
      setIsClaimed(true);
    }
  };

  var modalClass = open
    ? "z-10 fixed flex-col inset-1/2 -translate-x-1/2 -translate-y-1/2 m-auto rounded-2xl p-2 ease-in-out duration-300 translate-y-0 opacity-100 w-[30rem] max-w-full h-[39rem] max-h-full overflow-x-clip overflow-y-scroll lg:overflow-clip border border-pink bg-blue-dark shadow-pink"
    : "z-10 fixed flex-col inset-1/2 -translate-x-1/2 m-auto rounded-2xl p-2 ease-in-out duration-300 translate-y-full opacity-0 w-[30rem] max-w-full h-[39rem] max-h-full overflow-x-clip overflow-y-scroll lg:overflow-clip border border-pink bg-blue-dark shadow-pink";

  return ReactDom.createPortal(
    <>
      <div
        onClick={() => onClose(false)}
        className={`fixed inset-0 bg-black/70 backdrop-blur ease-in-out
        ${open ? "opacity-100" : "hidden"}`}
      />
      <div className={modalClass}>
        {isClaiming ? (
          <div className="z-50 w-full h-full inset-1/2 -translate-x-1/2 -translate-y-1/2 absolute bg-slate-900/70 flex justify-center items-center">
            <GridLoader color={"#FBCFE8"} />
          </div>
        ) : null}
        <IconContext.Provider
          value={{
            className: "relative mr-3 my-3 ml-auto text-pink text-3xl svg-pink",
          }}
        >
          <div
            onClick={() => onClose(true)}
            className="sticky flex h-12 top-0 mx-2 bg-gradient-to-b from-blue-dark via-[#0e0e1ad0] rounded-xl cursor-pointer"
          >
            <IoClose />
          </div>
        </IconContext.Provider>

        {userWon != null ? (
          userWon ? (
            !isClaimed ? (
              <>
                <div
                  className="-z-10 w-full h-full"
                  style={{ position: "absolute" }}
                >
                  <Sparkle color={"#EDD072"} minSize={6} maxSize={10} />
                </div>
                <h3 className="text-[#f8c402] text-3xl text-center">
                  Congrats, You’re a Winner!
                </h3>
              </>
            ) : (
              <h3 className="text-[#f8c402] text-3xl text-center">
                Congrats, your awesome new NFT has been sent!
              </h3>
            )
          ) : (
            <h3 className="text-blue text-3xl text-center">
              Sorry, Better Luck next time!
            </h3>
          )
        ) : (
          <h3 className="text-blue text-3xl text-center">
            See if you’re a Winner
          </h3>
        )}

        <div className="m-5">
          {/* Shows the blinking icon if the data is loading */}
          {isChecking ? (
            <Yicon className="h-80 w-auto py-4 md:py-0 blinking stroke-blue stroke-[0.5]" />
          ) : userWon != null ? (
            userWon ? (
              !isClaimed ? (
                <>
                  <YiconGold className="h-80 w-auto m-auto py-4 md:py-0 blinking-gold" />
                </>
              ) : (
                <div
                  className="center mx-auto my-8 bg-cover bg-center w-3/4 md:w-2/3 h-96 bg-pink rounded-lg"
                  style={{ backgroundImage: `url(${yieldling})` }}
                ></div>
              )
            ) : (
              <Yicon className="h-80 w-auto py-4 md:py-0 fill-[#363636]" />
            )
          ) : (
            <Yicon
              className="h-80 w-auto py-4 md:py-0 stroke-blue stroke-[0.5px] hover:none"
              style={{ filter: "drop-shadow(0 0 8px #00ebff)" }}
            />
          )}
          <p
            className={
              "mt-2 text-pink text-center " + (!isChecking ? "invisible" : null)
            }
          >
            Checking...
          </p>

          {!isClaimed ? (
            <>
              <div
                onClick={() =>
                  userWon != null ? onClaimNowClick() : onCheckNowClick()
                }
                className={
                  " w-full mx-auto mt-8 px-5 py-2 text-lg text-center uppercase bg-blue-dark/80 border rounded items-center transition-all " +
                  (userWon == false
                    ? "border-[#363636] text-[#363636]"
                    : "border-blue text-blue hover:shadow-blue hover:cursor-pointer hover:bg-blue/10")
                }
              >
                {userWon != null ? "Claim now" : "Check now"}
              </div>

              <p className="text-xs text-center text-violet-light my-3">
                If the light turns out, better luck next time! <br /> If your
                light turns gold you're a winner, congratulations!
              </p>
            </>
          ) : null}
        </div>
      </div>
    </>,

    document.getElementById("portal")
  );
}
