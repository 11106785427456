import { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { IconContext } from "react-icons";
import { ReactComponent as Ynft } from "./assets/ynft.svg";
import "./App.css";
import "./input.css";
import NftModule from "./components/nftDisplayModule";
import Navbar from "./components/navbar";
import NavbarItems from "./components/navbarItems";
import WinnerHistory from "./components/winnerHistory";
import TicketsModule from "./components/ticketsModule";
import WithdrawModule from "./components/withdrawModule";
import CheckWinnerModule from "./components/checkWinnerModule";
import { loadStdlib } from "@reach-sh/stdlib";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import * as backend from "./index.main.mjs";
import { toSU, connectWallet, calcUserRewards } from "./utils";
import {
  fetchGlobalGameData,
  fetchLatestAppID,
  fetchUserGameData,
} from "./api";

function App() {
  const [menuVis, setMenuVis] = useState(false);
  const [ticModVis, setTicModVis] = useState(false);
  const [withdrawModVis, setWithdrawModVis] = useState(false);
  const [checkModVis, setCheckModVis] = useState(false);
  const [gameData, setGameData] = useState();
  const [userStakeTokenBalance, setUserStakeTokenBalance] = useState();
  const [countdownInterval, setCoundownInterval] = useState();
  const [countDown, setCountDown] = useState(null);

  const onEnterNowClick = async () => {
    await connectWallet();
    setUserStakeTokenBalance(
      await window.userAcc
        .balanceOf(gameData.stakeToken.id)
        .then((v) => v.toBigInt())
    );
    setTicModVis(true);
  };

  const onWithdrawClick = async () => {
    await connectWallet();
    // await getGameData();
    setWithdrawModVis(true);
  };

  const getGameData = async () => {
    const [globalData, userData] = await Promise.all([
      fetchGlobalGameData(),
      window.userAcc ? fetchUserGameData() : null,
    ]);

    let latestGameData = {
      ...globalData,
      isStakingPeriod: countDown == 0 ? false : globalData.isStakingPeriod,
    };
    if (window.userAcc) {
      const latestUserRewards = calcUserRewards(
        userData.userRewards,
        userData.userBalance,
        globalData.latestRewardPerToken,
        userData.userRewardPerTokenPaid
      );
      latestGameData = { ...latestGameData, ...userData };
      latestGameData.latestUserRewards = latestUserRewards;
      latestGameData.userRewardsPerSec =
        (1000000 * userData.userBalance) / globalData.totalStake;
    }

    console.log(latestGameData);
    setGameData(latestGameData);
  };

  useEffect(() => {
    window.reach = loadStdlib("ALGO");
    window.reach.setWalletFallback(
      window.reach.walletFallback({
        providerEnv: "TestNet",
        MyAlgoConnect,
      })
    );
    document.dispatchEvent(new Event("window.reach"));

    const setUpContract = async () => {
      const appID = await fetchLatestAppID();
      window.currAppID = appID;
      window.readCtc = await window.reach.contract(backend, appID);

      document.addEventListener("window.userAcc", () => getGameData());
      await getGameData();
      return setInterval(() => getGameData(), 5000);
    };

    let interval;
    setUpContract().then((v) => {
      interval = v;
    });

    return () => {
      clearInterval(interval);
      document.removeEventListener("window.userAcc", null);
    };
  }, []);

  useEffect(() => {
    if (gameData?.endTime && countdownInterval == null) {
      const interval = setInterval(() => {
        const newCountdown = Math.max(
          gameData?.endTime - new Date().getTime(),
          0
        );
        setCountDown(newCountdown);
        if (newCountdown == 0) {
          setGameData((v) => {
            return { ...v, isStakingPeriod: false };
          });
          clearInterval(interval);
        }
      });
      setCoundownInterval(interval);
    }
  }, [gameData?.endTime]);

  var menuClass = menuVis
    ? "z-10 fixed  ease-in-out duration-300 translate-x-0 opacity-100 w-full h-full bg-blue-dark  "
    : "z-10 fixed  ease-in-out duration-300 -translate-x-full opacity-0 w-full h-full bg-blue-dark ";
  return (
    <div className="App bg-[url('./assets/background.svg')] bg-cover pb-56">
      <header className="App-header z-10 top-0 h-20 ">
        <Navbar toggleMenuVis={setMenuVis} />

        <div className={menuClass}>
          <IconContext.Provider
            value={{
              className: "ml-auto mr-5 h-20 text-pink text-2xl svg-pink",
            }}
          >
            <div onClick={() => setMenuVis(false)}>
              <IoClose />
            </div>
          </IconContext.Provider>
          <NavbarItems />
        </div>
      </header>
      <section>
        <TicketsModule
          stakeToken={gameData?.stakeToken}
          userStakeTokenBalance={userStakeTokenBalance}
          open={ticModVis}
          onClose={() => setTicModVis(false)}
          onStake={getGameData}
          isStakingPeriod={gameData?.isStakingPeriod}
        />
        <CheckWinnerModule
          winner={gameData?.winner}
          nft={gameData?.nft}
          open={checkModVis}
          onClose={() => setCheckModVis(false)}
          isPrizeClaimed={gameData?.isPrizeClaimed}
        />
        <WithdrawModule
          open={withdrawModVis}
          onClose={() => setWithdrawModVis(false)}
          userStakeTokenBalance={gameData?.userBalance}
          stakeToken={gameData?.stakeToken}
          onUnstake={getGameData}
          isStakingPeriod={gameData?.isStakingPeriod}
        />
        <div className="mt-12">
          <p className="uppercase font-semibold text-5xl md:text-7xl text-outline-blue">
            <span className="text-outline-pink blink-pink">
              N<span className="blink-pink">o</span> Loss{" "}
            </span>
            <span className="inline-block">
              N<span className="blink-blue">FT</span> P
              <span className="blink-blue">rize</span> Ga
              <span className="blink-blue">m</span>es
            </span>
          </p>
        </div>
        <p className="text-white font-semibold text-3xl my-7">Algorand NFTs</p>
        <NftModule
          ticketAmount={
            gameData?.userBalance != null
              ? toSU(gameData.userBalance, gameData.stakeToken.decimals)
              : null
          }
          countDown={countDown}
        />
        <div className="mt-24">
          {gameData?.isStakingPeriod == false ? (
            <div
              onClick={() => (gameData?.winner ? setCheckModVis(true) : null)}
              className={
                "w-max-xs md:w-80 mx-5 md:mx-auto my-4 px-5 py-2 uppercase text-2xl bg-blue-dark/80  border rounded items-center transition-all " +
                (gameData?.winner
                  ? "text-pink border-pink hover:shadow-pink hover:cursor-pointer hover:bg-pink/10"
                  : "text-[#363636] border-[#363636]")
              }
            >
              Am I a winner?
            </div>
          ) : (
            <div
              onClick={() =>
                gameData?.isStakingPeriod ? onEnterNowClick() : null
              }
              className={
                "w-max-xs md:w-80 mx-5 md:mx-auto my-4 px-5 py-2 uppercase text-2xl bg-blue-dark/80 border rounded items-center transition-all " +
                (gameData?.isStakingPeriod
                  ? "text-blue border-blue hover:shadow-blue hover:cursor-pointer hover:bg-blue/10"
                  : "text-[#363636] border-[#363636]")
              }
            >
              Enter Now
            </div>
          )}
          <div
            onClick={() => onWithdrawClick()}
            className={
              "w-max-xs md:w-80 mx-5 md:mx-auto my-4 px-5 py-2 uppercase text-2xl bg-blue-dark/80  border rounded items-center transition-all " +
              (gameData?.isStakingPeriod != null
                ? "text-blue border-blue hover:shadow-blue hover:cursor-pointer hover:bg-blue/10"
                : "text-[#363636] border-[#363636]")
            }
          >
            Withdraw YLDY
          </div>

          <div
            className={
              "text-green text-sm mt-1 blink-text-teal " +
              (gameData?.isStakingPeriod == false && gameData?.winner == null
                ? null
                : "invisible")
            }
          >
            Winner is being selected, please wait!
          </div>
        </div>
      </section>
      <section className="mx-5 xl:mx-48 mt-36">
        <h2 className="text-2xl text-pink text-left">Winner History</h2>
        <WinnerHistory />
      </section>
      <section>
        <div>
          <Ynft className="mt-14 mb-10 m-auto" />
          <p className="mx-auto text-center text-white max-w-md ">
            We're bringing together esports teams, fans and{" "}
            <span className="text-pink">crypto natives to move esports</span>{" "}
            forward.
          </p>
        </div>
        <div className="w-max-xs md:w-80 mx-5 md:mx-auto mt-8 px-5 py-2 text-lg bg-blue-dark/80 border border-blue rounded text-blue items-center hover:shadow-blue hover:cursor-pointer hover:bg-blue/10 transition-all">
          Explore Marketplace
        </div>
      </section>
    </div>
  );
}

export default App;
