import React from "react";
import { useState, useRef, useEffect } from "react";
import ReactDom from "react-dom";
import { IoClose, IoChevronUp, IoChevronDown } from "react-icons/io5";
import { IconContext } from "react-icons";
import yieldling from "../assets/yieldling.png";
import { connectWallet, toAU, toSU } from "../utils";
import { GridLoader } from "react-spinners";
import { numOfDP } from "../utils";

export default function TicketsModule({
  stakeToken,
  userStakeTokenBalance,
  open,
  onClose,
  onStake,
  isStakingPeriod,
}) {
  const [stakeAmountStr, setStakeAmountStr] = useState();
  const [stakeAmount, setStakeAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState("");

  //for when once tickets are claimed
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [errorScreen, setErrorScreen] = useState(false);

  const intervalRef = useRef(null);
  useEffect(() => {
    return () => stopCounter(); // when App is unmounted we should stop counter
  }, []);

  const decrementCount = () => {
    if (intervalRef.current || intervalRef.current < 0) return;
    intervalRef.current = setInterval(() => {
      setStakeAmount((prevCounter) =>
        prevCounter > 0 ? Math.round((prevCounter - 0.001) * 10000) / 10000 : 0
      );
    }, 100);
  };

  const incrementCount = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setStakeAmount(
        (prevCounter) => Math.round((prevCounter + 0.001) * 10000) / 10000
      );
    }, 75);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const checkIfValidInput = () => {
    if (stakeAmount != null) {
      if (stakeAmount <= 0) {
        setInputErrorMsg("Amount must be greater than 0");
        return false;
      }

      //checks if amt entered is greater than wallet balance
      if (toAU(stakeAmount, stakeToken?.decimals) > userStakeTokenBalance) {
        setInputErrorMsg("You do not have enough balance");
        return false;
      }
    }

    //resets error message
    setInputErrorMsg("");
    return true;
  };

  useEffect(() => {
    checkIfValidInput();
  }, [stakeAmount, userStakeTokenBalance]);

  useEffect(() => {
    const newStakeAmount =
      stakeAmountStr != null && stakeAmountStr != ""
        ? Number(stakeAmountStr)
        : null;
    setStakeAmount(newStakeAmount);
  }, [stakeAmountStr]);

  const onStakeClick = async () => {
    if (stakeAmount == null) {
      setInputErrorMsg("Please enter an amount");
      return;
    }

    if (!checkIfValidInput()) {
      return;
    }

    setIsLoading(true);
    try {
      await connectWallet();
      const stakeAmt = toAU(stakeAmount, stakeToken.decimals);
      await window.userCtc.apis.stake(stakeAmt);
      await onStake();

      //successful claim. Modal closes on user close
      setIsLoading(false);
      setClaimSuccess(true);
    } catch (error) {
      setErrorScreen(true);
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    onClose();
    setIsLoading(false);
    setClaimSuccess(false);
    setErrorScreen(false);
  };

  var modalClass = open
    ? "z-10 fixed flex-col inset-1/2 -translate-x-1/2 -translate-y-1/2 m-auto rounded-2xl md:p-2 ease-in-out duration-300 translate-y-0 opacity-100 w-[30rem] max-w-full h-[39rem] max-h-full overflow-x-clip overflow-y-scroll lg:overflow-clip border border-pink bg-blue-dark shadow-pink"
    : "z-10 fixed flex-col inset-1/2 -translate-x-1/2 m-auto rounded-2xl md:p-2 ease-in-out duration-300 translate-y-full opacity-0 w-[30rem] max-w-full h-[39rem] max-h-full overflow-x-clip overflow-y-scroll lg:overflow-clip border border-pink bg-blue-dark shadow-pink";

  return ReactDom.createPortal(
    <>
      <div
        onClick={closeModal}
        className={`fixed inset-0 bg-black/70 backdrop-blur ease-in-out
        ${open ? "opacity-100" : "hidden"}`}
      />
      <div className={modalClass}>
        {isLoading ? (
          <div className="w-full h-full inset-1/2 -translate-x-1/2 -translate-y-1/2 absolute bg-slate-900/70 flex justify-center items-center">
            <GridLoader color={"#FBCFE8"} />
          </div>
        ) : null}
        <IconContext.Provider
          value={{
            className: "relative mr-3 my-3 ml-auto text-pink text-3xl svg-pink",
          }}
        >
          <div
            onClick={closeModal}
            className="sticky flex h-12 top-0 mx-2 bg-gradient-to-b from-blue-dark via-[#0e0e1ad0] rounded-xl cursor-pointer"
          >
            <IoClose />
          </div>
        </IconContext.Provider>

        {/* Content of the modal is based on successful claim of tickets */}
        {claimSuccess ? (
          <>
            <div className="flex flex-col justify-center h-3/4 ">
              <h3 className="my-auto text-blue text-4xl text-center">
                You have claimed <br />{" "}
                <span className="text-pink">{stakeAmount} tickets!</span>
              </h3>
              <div
                onClick={() => closeModal()}
                className="w-max-xs md:w-2/3 mx-5 md:mx-auto mt-1 mb-4 px-5 py-2 text-center text-2xl bg-blue-dark/80 border rounded items-center transition-all text-blue border-blue hover:shadow-blue hover:cursor-pointer hover:bg-blue/10"
              >
                Close
              </div>
            </div>
          </>
        ) : errorScreen ? (
          <>
            <div className="flex flex-col justify-center h-3/4 ">
              <h3 className="my-auto text-blue text-3xl text-center">
                Something went wrong. <p>Please try again!</p>
              </h3>
              <div
                onClick={() => closeModal()}
                className="w-max-xs md:w-2/3 mx-5 md:mx-auto mt-1 mb-4 px-5 py-2 text-center text-2xl bg-blue-dark/80 border rounded items-center transition-all text-blue border-blue hover:shadow-blue hover:cursor-pointer hover:bg-blue/10"
              >
                Close
              </div>
            </div>
          </>
        ) : isStakingPeriod == false && isLoading == false ? (
          <>
            <div className="flex flex-col justify-center h-3/4 ">
              <h3 className="my-auto text-blue text-3xl text-center">
                Time to claim tickets has ended!
              </h3>
              <div
                onClick={() => closeModal()}
                className="w-max-xs md:w-2/3 mx-5 md:mx-auto mt-1 mb-4 px-5 py-2 text-center text-2xl bg-blue-dark/80 border rounded items-center transition-all text-blue border-blue hover:shadow-blue hover:cursor-pointer hover:bg-blue/10"
              >
                Close
              </div>
            </div>
          </>
        ) : (
          <>
            <h3 className="text-blue text-4xl text-center">
              Claim your tickets now
            </h3>
            <div className="m-10">
              <img
                src={yieldling}
                alt="NFT artwork"
                className="rounded-xl w-48 h-48 m-auto"
              />
              <p className="text-sm text-pink mt-7 mb-5">
                {userStakeTokenBalance != null
                  ? toSU(userStakeTokenBalance, stakeToken?.decimals)
                  : null}{" "}
                Available YLDY
              </p>
              <div className="flex justify-between px-2 rounded-lg border border-pink">
                <form>
                  <input
                    value={stakeAmountStr}
                    onChange={(e) => {
                      let numStr = e.target.value;
                      const numDP = numOfDP(numStr);
                      numStr =
                        numDP > stakeToken?.decimals
                          ? numStr.slice(0, -(numDP - stakeToken?.decimals))
                          : numStr;
                      numStr = numStr.replace("-", "");
                      setStakeAmountStr(numStr);
                    }}
                    type="number"
                    min={0}
                    step=".001"
                    placeholder="12.300"
                    className="bg-transparent text-xl text-white placeholder:text-slate-700 w-full h-12 p-2 bg-blue-dark outline-none"
                  />
                </form>

                <div className="flex flex-col justify-between my-0.5 mx-1 text-pink">
                  <button
                    onTouchStart={incrementCount}
                    onTouchEnd={stopCounter}
                    onMouseDown={incrementCount}
                    onMouseUp={stopCounter}
                    onMouseLeave={stopCounter}
                  >
                    <IconContext.Provider
                      value={{
                        className: "text-pink text-xl svg-pink",
                      }}
                    >
                      <IoChevronUp />
                    </IconContext.Provider>
                  </button>
                  <button
                    onTouchStart={decrementCount}
                    onTouchEnd={stopCounter}
                    onMouseDown={decrementCount}
                    onMouseUp={stopCounter}
                    onMouseLeave={stopCounter}
                  >
                    <IconContext.Provider
                      value={{
                        className: "text-pink text-xl svg-pink",
                      }}
                    >
                      <IoChevronDown />
                    </IconContext.Provider>
                  </button>
                </div>
              </div>

              <div className="text-red-500 text-sm mt-1">{inputErrorMsg}</div>
              <div
                onClick={() => onStakeClick()}
                className="w-full mx-auto mt-5 px-5 py-2 text-lg text-center uppercase bg-blue-dark/80 border border-blue rounded text-blue items-center hover:shadow-blue hover:cursor-pointer hover:bg-blue/10 transition-all"
              >
                Claim your tickets
              </div>
              <p className="text-xs text-center text-violet-light my-3">
                Each YLDY will be awarded a # of tickets based on their stake
                length
              </p>
            </div>
          </>
        )}
      </div>
    </>,

    document.getElementById("portal")
  );
}
